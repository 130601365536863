import { transitionEnd } from '../tools/events'

let triggered = false
let readMoreInstances = {}

export function ReadMore() {
    if (triggered) {
        return
    }

    triggered = true

    document.addEventListener('click', (evt) => {
        if (
            evt.target.classList.contains('u-read-more') ||
            evt.target.classList.contains('js-readmore')
        ) {
            evt.preventDefault()
            toggleReadMore(evt)
        }
    })
}

function toggleReadMore(evt) {
    let button = evt.target
    let controlId = button.getAttribute('aria-controls')

    if (!readMoreInstances[controlId]) {
        let container = document.querySelector('#' + controlId)

        if (!container) {
            return
        }

        readMoreInstances[controlId] = {
            button,
            container,
            animationFrame: null,
            isOpen: button.getAttribute('aria-expanded') === 'true'
        }

        transitionEnd(container).subscribe((evt) => transitionEndTasks(evt, controlId))
    }

    if (readMoreInstances[controlId].isOpen) {
        close(controlId)
    } else {
        open(controlId)
    }
}

function transitionEndTasks(evt, controlId) {
    const { container, button, isOpen } = readMoreInstances[controlId]

    if (evt.target !== container) {
        return
    }

    if (isOpen) {
        container.style.height = 'auto'
        container.style.overflow = 'visible'
    } else {
        container.hidden = true
        button.setAttribute('aria-expanded', false)
    }
}

function open(controlId) {
    const { container, button, animationFrame } = readMoreInstances[controlId]

    if (animationFrame) {
        cancelAnimationFrame(animationFrame)
    }

    container.hidden = false
    container.style.height = Math.ceil(container.scrollHeight) + 'px'
    container.style.overflow = 'hidden'
    container.dataset.active = true
    button.setAttribute('aria-expanded', 'true')
    button.dataset.active = true
    readMoreInstances[controlId].isOpen = true
}

function close(controlId) {
    const { container, animationFrame, button } = readMoreInstances[controlId]

    if (animationFrame) {
        cancelAnimationFrame(animationFrame)
    }

    container.style.height = Math.ceil(container.scrollHeight) + 'px'
    container.style.overflow = 'hidden'

    readMoreInstances[controlId].animationFrame = requestAnimationFrame(() => {
        container.removeAttribute('data-active')
        button.removeAttribute('data-active')
        container.style.height = 0
        readMoreInstances[controlId].animationFrame = null
    })

    readMoreInstances[controlId].isOpen = false
}
