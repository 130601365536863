import { scrollY } from './scroll-position'

let headerEl
let anchorBarEl
let interestFormEl

let animationFrame
let currentScroll

let hideNavTop
let interestFormTop
let anchorTop

let media = '(min-width: 48em)'

export function FixedBars() {
    headerEl = document.querySelector('header.main-header')
    anchorBarEl = document.querySelector('.js-anchor-bar')
    interestFormEl = document.querySelector('.js-interest-form')

    if (!headerEl || !anchorBarEl || !interestFormEl) {
        return
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()
}

function handleScroll() {
    if (animationFrame) {
        return
    }

    animationFrame = requestAnimationFrame(() => {
        currentScroll = scrollY

        anchorTop = anchorBarEl.offsetTop

        if (!window.matchMedia(media).matches) {
            anchorTop = anchorBarEl.offsetTop + 108
        }

        hideNavTop = anchorTop - headerEl.offsetHeight
        interestFormTop = interestFormEl.offsetTop - 88

        if (currentScroll > hideNavTop && !headerEl.classList.contains('is-hidden')) {
            headerEl.classList.add('is-hidden')
        }

        if (currentScroll < hideNavTop && headerEl.classList.contains('is-hidden')) {
            headerEl.classList.remove('is-hidden')
        }

        if (currentScroll > anchorTop && !anchorBarEl.classList.contains('is-fixed')) {
            anchorBarEl.classList.add('is-fixed')
        }

        if (currentScroll < anchorTop && anchorBarEl.classList.contains('is-fixed')) {
            anchorBarEl.classList.remove('is-fixed')
        }

        if (currentScroll > interestFormTop && !anchorBarEl.classList.contains('is-hidden')) {
            anchorBarEl.classList.add('is-hidden')
        }

        if (currentScroll < interestFormTop && anchorBarEl.classList.contains('is-hidden')) {
            anchorBarEl.classList.remove('is-hidden')
        }

        animationFrame = false
    })
}
