let elements

export function Parallax() {
    elements = document.querySelectorAll('.js-parallax')

    if (!elements.length) {
        return
    }

    import('simple-parallax-js')
        .then((m) => m.default)
        .then((simpleParallax) => {
            elements = Array.from(elements)
            elements = elements.map((el) => {
                const { scale, overflow = true } = el.dataset

                return new simpleParallax(el, {
                    scale,
                    overflow
                })
            })
        })
}
