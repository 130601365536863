let selects

export function Selects(context = document) {
    const selectEls = context.querySelectorAll('.js-select, .js-select-submit')
    selects = Array.from(selectEls)
    if (selects.length) {
        import('choices.js')
            .then((m) => m.default)
            .then((Choices) => {
                selects = selects.map((el) => setupSelect(Choices, el))
            })
    }

    return selects
}

function setupSelect(Choices, el) {
    if (el.classList.contains('js-select-submit')) {
        el.addEventListener('change', function (evt) {
            const form = evt.target.closest('form')
            form.submit()
        })
    }

    const options = {
        searchEnabled: false,
        itemSelectText: '',
        removeItemButton: el.multiple,
        shouldSort: false,
        noChoicesText: 'All events have been selected',
        callbackOnCreateTemplates: function (template) {
            var classNames = this.config.classNames
            return {
                containerInner: () => {
                    return template(
                        `<div class="${classNames.containerInner}">
                            <div class="choices__icon">
                                <svg aria-hidden="true" focusable="false"><use xlink:href="/wp-content/themes/bargate-homes/dist/assets/images/svgicons.svg#select"></use></svg>
                            </div>
                        </div>`
                    )
                }
            }
        }
    }

    return new Choices(el, options)
}
