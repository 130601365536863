import { addIntersectionItem } from '../tools'

export function ScrollInner() {
    const scrollAnimationElements = document.querySelectorAll('.js-scroll-inner')
    const scrollAnimations = Array.from(scrollAnimationElements)

    scrollAnimations.forEach((el) => {
        const trigger = el.querySelector('.js-scroll-inner-trigger')
        if (!trigger) {
            return
        }

        const options = {
            isActive: false,
            parent: el
        }
        let offset = 15
        if (trigger.dataset.scrollInnerOffset) {
            offset = trigger.dataset.scrollInnerOffset.replace(/[^0-9]/, '')
        }
        addIntersectionItem(trigger, handleIntersection, options, `0px 0px -${offset}% 0px`)
    })
}

function handleIntersection(intersectionObj, entry) {
    const { el, options } = intersectionObj

    if (!entry.isIntersecting || entry.intersectionRatio < 0.2 || options.isActive) {
        return
    }

    options.parent.classList.add('scroll-inner-active')
    options.isActive = true
}
