import { createUniqueHash } from './uniqueID'

let listeners = {}
let items = {}

export function addIntersectionItem(el, cb, options = {}, rootMargin = '0px') {
    if (!el) {
        return
    }

    const uniqueID = createUniqueHash()
    el.dataset.intersectionId = uniqueID
    items[uniqueID] = {
        el,
        cb,
        options
    }

    if (typeof window.IntersectionObserver !== 'undefined') {
        setupListener(rootMargin)
        listeners[rootMargin].observe(el)
    } else {
        cb()
    }
}

function setupListener(rootMargin) {
    if (listeners[rootMargin]) {
        return
    }

    listeners[rootMargin] = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                const target = entry.target
                const id = target.dataset.intersectionId

                if (typeof items[id] !== 'undefined') {
                    items[id].cb(items[id], entry)
                }
            })
        },
        {
            root: null,
            rootMargin,
            threshold: buildThresholdList()
        }
    )
}

function buildThresholdList(numSteps = 100) {
    var thresholds = []

    for (var i = 1.0; i <= numSteps; i++) {
        var ratio = i / numSteps
        thresholds.push(ratio)
    }

    thresholds.push(0)
    return thresholds
}
