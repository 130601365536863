let el

export function SitePlan() {
    el = document.querySelector('.js-siteplan')
    if (!el) {
        return
    }

    import('./siteplan')
        .then((m) => m.initSitePlan)
        .then((initSitePlan) => {
            const { url } = el.dataset

            fetch(url)
                .then((data) => data.json())
                .then((data) => {
                    initSitePlan(el, data)
                })
        })
}
