let tabs
let select
let list
let tabItems

let current

export function SelectTabs() {
    tabs = document.querySelector('.js-select-tabs')
    if (!tabs) {
        return
    }

    select = tabs.querySelector('.js-select-tab-nav')
    list = tabs.querySelector('.js-select-tabs-list')
    tabItems = Array.from(list.children)

    select.addEventListener('change', () => {
        let val = select.value

        if (current === val) {
            return
        }

        tabItems.forEach((el) => {
            if (el.dataset.tab === val) {
                el.dataset.active = true
            } else {
                el.removeAttribute('data-active')
            }
        })

        current = val
    })
}
