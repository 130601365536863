export function waitAnimationFrame(cb, frames = 2) {
    let count = 0

    var loop = () => {
        requestAnimationFrame(() => {
            count++
            if (count >= frames) {
                cb()
            } else {
                loop()
            }
        })
    }

    loop()
}
