let el
let tabNav
let navItems
let tabPanels

let current

export function Tabs() {
    el = document.querySelector('.js-tabs')

    if (!el) {
        return
    }

    tabNav = el.querySelector('.js-tab-nav')
    navItems = Array.from(tabNav.children)
    tabPanels = el.querySelector('.js-tab-list')
    tabPanels = Array.from(tabPanels.children)

    tabNav.addEventListener('click', handleClick)
}

function handleClick(evt) {
    if (!evt.target.dataset.tabId) {
        return
    }

    if (current === evt.target.dataset.tabId) {
        return
    }

    openTab(evt.target.dataset.tabId)
}

function openTab(tabID) {
    navItems.forEach((el) => {
        if (el.dataset.tabId === tabID) {
            el.dataset.active = true
        } else {
            el.removeAttribute('data-active')
        }
    })

    tabPanels.forEach((el) => {
        if (el.id === tabID) {
            el.dataset.active = true
        } else {
            el.removeAttribute('data-active')
        }
    })

    current = tabID
}
