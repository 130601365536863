export let scrollY = 0

export function setupScrollPosition() {
    updateScrollPosition()
    window.addEventListener('scroll', updateScrollPosition)
}

function updateScrollPosition() {
    scrollY = window.scrollY
    // document.body.style.setProperty('--scroll', scrollY + 'px')
    // document.body.style.setProperty('--scroll-nounit', scrollY)
}
