import panzoom from 'panzoom'

let zoomEls

export function Zoom() {
    zoomEls = document.querySelectorAll('.js-zoom')
    zoomEls = Array.from(zoomEls)

    if (!zoomEls.length) {
        return
    }

    zoomEls.forEach((el) => {
        const container = el.querySelector('.js-zoom-container')

        const zoom = panzoom(container, {
            beforeWheel: function (e) {
                var shouldIgnore = !e.altKey
                return shouldIgnore
            },
            maxZoom: 2.2,
            minZoom: 0.8,
            bounds: true,
            boundsPadding: 0.1,
            transformOrigin: { x: 0.5, y: 0.5 }
        })

        const zoomIn = el.querySelector('[data-action="zoomin"]')
        const zoomOut = el.querySelector('[data-action="zoomout"]')

        zoomIn.addEventListener('click', () => {
            zoom.smoothZoom(0.5, 0.5, 1.2)
        })

        zoomOut.addEventListener('click', () => {
            zoom.smoothZoom(0.5, 0.5, 0.8)
        })
    })
}
