let elements

export function selfDimensions() {
    elements = document.querySelectorAll('.js-self-height, .js-self-width')
    elements = Array.from(elements)

    updateSize()
    window.addEventListener('resize', updateSize)
}

function updateSize() {
    elements.forEach((el) => {
        if (el.classList.contains('js-self-height')) {
            el.style.setProperty('--self-height', el.offsetHeight + 'px')
            el.style.setProperty('--self-height-nounit', el.offsetHeight)
        }

        if (el.classList.contains('js-self-width')) {
            el.style.setProperty('--self-width', el.offsetWidth + 'px')
            el.style.setProperty('--self-width-nounit', el.offsetWidth)
        }
    })
}
