import {
    setViewUnits,
    waitAnimationFrame,
    selfDimensions,
    ReadMore,
    SliderInit,
    PageList,
    Selects,
    Zoom,
    SelectTabs,
    Accordions,
    Tabs,
    FixedBars,
    setupScrollPosition,
    ScrollAnim,
    Parallax,
    ScrollInner,
    ProgressiveHover,
    SitePlan
} from './ui'

function init() {
    document.body.classList.remove('preload')
    document.body.classList.add('loaded') // trigger css to know js has loaded
    setViewUnits()

    waitAnimationFrame(() => {
        selfDimensions()
        ReadMore()
        SliderInit()
        PageList()
        Selects()
        Zoom()
        SelectTabs()
        Accordions()
        Tabs()
        FixedBars()
        setupScrollPosition()
        ScrollAnim()
        Parallax()
        ScrollInner()
        ProgressiveHover()
        SitePlan()
    })
}

if (document.readyState !== 'loading') {
    init()
} else {
    document.addEventListener('DOMContentLoaded', init)
}
